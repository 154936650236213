import {
    Box,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

type EventAttendeesProps = {
    eventAttendees: any[],
    page: number,
    rowsPerPage: number,
    handleChangePage: (event: unknown, newPage: number) => void,
    handleChangeRowsPerPage: any,
};


export const EventsAttendeesTable = ({ eventAttendees, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }: EventAttendeesProps) => {

    return (
        <TableContainer component={Paper} sx={{ padding: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Accessed</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>ID</TableCell>
                        {/* <TableCell>Additional Info</TableCell> */}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventAttendees
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        {
                                            !!row.accessDate
                                                ? <CheckBox style={{ marginLeft: 5 }} color='success' />
                                                : <CheckBoxOutlineBlank style={{ marginLeft: 5 }} color='disabled' />
                                        }
                                    </Box>
                                </TableCell>
                                <TableCell>{
                                    row.accessDate
                                    && (new Date(row.accessDate).toLocaleString())}</TableCell>

                                <TableCell>
                                    {
                                        row?.type == 'QR' &&
                                        <Tooltip title="QR Attendee">
                                            <QrCodeIcon />
                                        </Tooltip>
                                    }
                                    {
                                        row?.type == 'NFC' &&
                                        <Tooltip title="NFC Attendee">
                                            <TapAndPlayIcon />
                                        </Tooltip>
                                    }
                                </TableCell>
                                <TableCell>{row.typeId}</TableCell>
                                {/* <TableCell>
                                    <Link onClick={() => {
                                        // todo view row.details
                                    }} fontSize={'0.8rem'} style={{ cursor: 'pointer' }}>View</Link>
                                </TableCell> */}

                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <TablePagination
                rowsPerPageOptions={[100, 250, 500]}
                component="div"
                count={eventAttendees.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};
