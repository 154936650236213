import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
  Autocomplete,
} from '@mui/material';

import { useCurrentCompany } from '../../utils/hooks';
import * as API from '../../controllers/api';
import { useToast } from '../../components/Toast';
import { AccessType, Device } from '../../utils/models';
import { EventType } from '@testing-library/react';

// Define your DeviceDialog component
const DeviceDialog = ({ open, handleClose, onSuccess, currentDevice }) => {
  currentDevice = currentDevice as Device;
  const isEdit = !!currentDevice;

  const { currentCompany } = useCurrentCompany();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  // Initial form state
  const [formData, setFormData] = useState({
    deviceCode: '',
    deviceName: '',
    deviceConfig: {
      enableContractorScan: true,
      addPassengerOnExit: false,
      addPassengerOnEntry: false,
      mobileNumberRequired: false,
      enableEntryPhotoLinks: false,
      customFields: [
        {
          fieldName: '',
          fieldType: '',
          required: false,
          onAccessTypes: [],
          onEventTypes: [],
          options: '',
        },
      ],
    },
  });

  // Function to add an empty custom field
  const addEmptyCustomField = () => {
    setFormData((prevState) => ({
      ...prevState,
      deviceConfig: {
        ...prevState.deviceConfig,
        customFields: [
          ...prevState.deviceConfig.customFields,
          {
            fieldName: '',
            fieldType: '',
            required: false,
            onAccessTypes: [],
            onEventTypes: [],
            options: '',
          },
        ],
      },
    }));
  };

  // Function to remove a custom field by index
  const removeCustomField = (index: number) => {
    setFormData((prevState) => {
      const newCustomFields = prevState.deviceConfig.customFields.filter(
        (_, i) => i !== index
      );
      return {
        ...prevState,
        deviceConfig: {
          ...prevState.deviceConfig,
          customFields: newCustomFields,
        },
      };
    });
  };

  // Function to handle input changes for non-custom fields
  const handleDeviceConfigChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, checked, type } = e.target as HTMLInputElement;
    setFormData((prevState) => ({
      ...prevState,
      deviceConfig: {
        ...prevState.deviceConfig,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleDeviceCodeChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  // Separate function to handle custom field changes
  const handleCustomFieldChange = (
    index: number,
    field: string,
    value: string | boolean | string[]
  ) => {
    setFormData((prevState) => {
      const newCustomFields = [...prevState.deviceConfig.customFields];
      newCustomFields[index] = {
        ...newCustomFields[index],
        [field]: value,
      };
      return {
        ...prevState,
        deviceConfig: {
          ...prevState.deviceConfig,
          customFields: newCustomFields,
        },
      };
    });
  };

  // Validate form data
  const handleValidateFormData = () => {
    if (!formData.deviceCode) {
      showToast('Device Code is required', 'error', 3000);
      return false;
    }

    return true;
  };

  // Handle save action
  const handleSave = () => {
    if (!handleValidateFormData()) return;

    setLoading(true);

    const device = {
      deviceId: formData.deviceCode,
      deviceName: formData.deviceName,
      deviceConfig: {
        addPassengerOnEntry: formData.deviceConfig.addPassengerOnEntry,
        addPassengerOnExit: formData.deviceConfig.addPassengerOnExit,
        enableContractorScan: formData.deviceConfig.enableContractorScan,
        enableEntryPhotoLinks: formData.deviceConfig.enableEntryPhotoLinks,
        mobileNumberRequired: formData.deviceConfig.mobileNumberRequired,
        customFields: formData.deviceConfig.customFields.map((field) => ({
          fieldName: field.fieldName,
          fieldType: field.fieldType,
          required: field.required,
          onAccessTypes: field.onAccessTypes,
          onEventTypes: field.onEventTypes,
          options: field.options.split(',').map((option) => option.trim()),
        })),
      },
    } as any;

    const action = isEdit
      ? API.updateDevice(currentCompany, currentDevice.deviceId, device)
      : API.createDevice(currentCompany, device);

    action
      .then(() => {
        showToast(
          `Successfully ${isEdit ? 'updated' : 'saved'} device: ${formData.deviceCode
          }`,
          'info',
          3000
        );
        handleClose({ success: true });
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        showToast(
          `Failed to ${isEdit ? 'update' : 'save'} device: ${formData.deviceCode
          }`,
          'error',
          3000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Build a custom field UI component
  const buildCustomField = (field, index) => {
    return (
      <Box
        key={index}
        flex={1}
        display="flex"
        flexDirection="column"
        border={1}
        borderRadius={4}
        padding={2}
        gap={2}
        borderColor={'gray'}
        margin={2}
      >
        <TextField
          margin="dense"
          name={`customFields[${index}].fieldName`}
          label="Field Name"
          fullWidth
          value={field.fieldName}
          onChange={(e) =>
            handleCustomFieldChange(index, 'fieldName', e.target.value)
          }
        />

        <Autocomplete
          options={['TEXT', 'DROPDOWN', 'NUMBER']}
          getOptionLabel={(option) => option}
          defaultValue={null}
          value={field.fieldType}
          onChange={(event, newValue) =>
            handleCustomFieldChange(index, 'fieldType', newValue || '')
          }
          renderInput={(params) => (
            <TextField {...params} label="Field Type" />
          )}
        />

        {
          field.fieldType === 'DROPDOWN' && (
            <TextField
              margin="dense"
              name={`customFields[${index}].options`}
              label="Options"
              fullWidth
              value={field.options}
              onChange={(e) =>
                handleCustomFieldChange(index, 'options', e.target.value)
              }
              helperText="Comma separated list of options"
            />
          )
        }

        <Autocomplete
          multiple
          options={['PEDESTRIAN', 'VEHICLE', 'CONTRACTOR']}
          getOptionLabel={(option) => option}
          defaultValue={field.onAccessTypes}
          onChange={(event, newValue) =>
            handleCustomFieldChange(index, 'onAccessTypes', newValue || [])
          }
          renderInput={(params) => (
            <TextField {...params} label="On Access Types" placeholder="Select Access Types" />
          )}
        />

        <Autocomplete
          multiple
          options={['ENTRY', 'EXIT']}
          getOptionLabel={(option) => option}
          defaultValue={field.onEventTypes}
          onChange={(event, newValue) =>
            handleCustomFieldChange(index, 'onEventTypes', newValue || [])
          }
          renderInput={(params) => (
            <TextField {...params} label="On Event Types" placeholder="Select Event Types" />
          )}
        />

        <FormControlLabel
          control={
            <Switch
              checked={field.required}
              onChange={(e) =>
                handleCustomFieldChange(index, 'required', e.target.checked)
              }
              name={`customFields[${index}].required`}
            />
          }
          label="Required"
        />

        <Button
          onClick={() => removeCustomField(index)}
          variant="text"
          color="error"
        >
          Remove
        </Button>
      </Box>
    );
  };

  // Populate default form data if editing
  useEffect(() => {
    if (isEdit) {
      setFormData({
        deviceCode: currentDevice.deviceId,
        deviceName: currentDevice.deviceName || '',
        deviceConfig: {
          addPassengerOnEntry: currentDevice.deviceConfig?.addPassengerOnEntry || false,
          addPassengerOnExit: currentDevice.deviceConfig?.addPassengerOnExit || false,
          enableContractorScan: currentDevice.deviceConfig?.enableContractorScan || false,
          enableEntryPhotoLinks: currentDevice.deviceConfig?.enableEntryPhotoLinks || false,
          mobileNumberRequired: currentDevice.deviceConfig?.mobileNumberRequired || false,
          customFields: currentDevice.deviceConfig?.customFields.map((field) => ({
            fieldName: field.fieldName,
            fieldType: field.fieldType,
            required: field.required,
            onAccessTypes: field.onAccessTypes,
            onEventTypes: field.onEventTypes,
            options: field.options?.join(', ') || '',
          })) || [],
        },
      });
    } else {
      setFormData({
        deviceCode: '',
        deviceName: '',
        deviceConfig: {
          enableContractorScan: false,
          addPassengerOnExit: false,
          addPassengerOnEntry: false,
          mobileNumberRequired: false,
          enableEntryPhotoLinks: false,
          customFields: [
          ],
        },
      });
    }
  }, [currentDevice]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{isEdit ? 'Edit Device' : 'Add Device'}</DialogTitle>
      {loading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 5 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent>
            <TextField
              margin="dense"
              name="deviceCode"
              label="Device Code"
              fullWidth
              disabled={isEdit}
              value={formData.deviceCode}
              onChange={handleDeviceCodeChanged}
              helperText="Unique code on the device"
            />

            <TextField
              margin="dense"
              name="deviceName"
              label="Device Name"
              fullWidth
              value={formData.deviceName}
              onChange={handleDeviceCodeChanged}
              helperText="Optional name for the device"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.deviceConfig['enableContractorScan']}
                  onChange={(e) => handleDeviceConfigChanged(e)}
                  name="enableContractorScan"
                />
              }
              label="Enabled Contractor/Simple Barcode Scans"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.deviceConfig['addPassengerOnExit']}
                  onChange={(e) => handleDeviceConfigChanged(e)}
                  name="addPassengerOnExit"
                />
              }
              label="Scan Passenger Details on Exit"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.deviceConfig['addPassengerOnEntry']}
                  onChange={(e) => handleDeviceConfigChanged(e)}
                  name="addPassengerOnEntry"
                />
              }
              label="Scan Passenger Details on Entry"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.deviceConfig['mobileNumberRequired']}
                  onChange={(e) => handleDeviceConfigChanged(e)}
                  name="mobileNumberRequired"
                />
              }
              label="Mobile Number Required"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.deviceConfig['enableEntryPhotoLinks']}
                  onChange={(e) => handleDeviceConfigChanged(e)}
                  name="enableEntryPhotoLinks"
                />
              }
              label="Add photos, and automatically link entry photo(s) to exit"
            />

            <Box color={'gray'}>
              Note: The device will sync once a day to get the latest configuration, alternatively you can force a sync from the device (inside the settings page).
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center">
              <h3>Custom Fields</h3>
              <Box flex={1} />
              <Button onClick={addEmptyCustomField} variant="text" color="primary">
                + Add Field
              </Button>
            </Box>

            {formData.deviceConfig.customFields.map((field, index) => (
              <Box key={index}>
                {index === 0 && (
                  <h4>
                    Custom Fields({formData.deviceConfig.customFields.length})
                  </h4>
                )}
                {buildCustomField(field, index)}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DeviceDialog;
