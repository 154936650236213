import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../components/Copyright';
import { AuthService } from '../services/auth';
import { CircularProgress } from '@mui/material';
import lockImg from '../assets/img/lock.png';
import accessLogoWhite from '../assets/img/logo-white.png';


type SignInPageProps = {
    onLoginSuccess: () => void;
}

export const SignInPage = (props: SignInPageProps) => {
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);

        try {
            await AuthService.login(
                data.get('username') as string,
                data.get('password') as string
            )
            props.onLoginSuccess();
        } catch (error) {
            console.log(error);
            setError('Login failed. Please verify your credentials and try again');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100%' }}>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Paper sx={{ padding: 5, textAlign: 'center' }}>
                        <img src={accessLogoWhite} alt="Access Logo" width="250" />
                        <Box
                            alignItems={'center'}
                            justifyContent={'center'}
                            display={'flex'}
                            marginTop={3}
                        >
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                        </Box>

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                disabled={loading}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {loading && <CircularProgress size={20} sx={{
                                    mr: 3,
                                }} />}
                                Sign In
                            </Button>

                            <div>
                                {error && <Typography color="error">{error}</Typography>}
                            </div>
                            <Box sx={{ flexGrow: 1 }} />
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Grid >
    );
}