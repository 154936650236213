import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import EventIcon from '@mui/icons-material/Event';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import { Link, useLocation } from 'react-router-dom';
import { CompanyDropdown } from './CompanyDropdown';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AuthService, PermissionType } from '../services/auth';



const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export const Sidebar = ({ open, handleDrawerClose }: any) => {
    const location = useLocation();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const isSelected = (path: string) => {
        return location.pathname.indexOf(path) !== -1;
    }

    const mainListItems = (
        <React.Fragment>
            {
                AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) &&
                <ListItemButton selected={isSelected("/dashboard")} component={Link} to="/dashboard">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            }
            {
                AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) &&
                <ListItemButton selected={isSelected("/logs")} component={Link} to="/logs">
                    <ListItemIcon>
                        <ManageSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logs" />
                </ListItemButton>
            }
            {
                AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) &&
                <ListItemButton selected={isSelected("/blacklist")} component={Link} to="/blacklist">
                    <ListItemIcon>
                        <LockPersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Blacklist" />
                </ListItemButton>
            }          
            {
                AuthService.hasPermission(PermissionType.ADMIN_EVENTS) &&
                <ListItemButton selected={isSelected("/events")} component={Link} to="/events">
                    <ListItemIcon>
                        <EventIcon />
                    </ListItemIcon>
                    <ListItemText primary="Events" />
                </ListItemButton>
            }
        </React.Fragment>
    );

    return (
        <Drawer variant="permanent" open={(isSmallScreen ? false : open)}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                {
                    !isSmallScreen &&
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                }
            </Toolbar>
            <Divider />
            <List component="nav" style={{
                height: '100%',
            }} >
                {mainListItems}
            </List>
            <Box paddingY={3} paddingX={1} display={isSmallScreen ? 'none' : 'block'}>
                <CompanyDropdown />
            </Box>
        </Drawer >
    );
};
