import { Box } from '@mui/material';

export const NotFoundPage = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100vw',
            }}
        >
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <h1>404 - Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </Box>
    );
}
