import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer = () => {
    return (
        <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'white', py: 3 }}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" component="p" gutterBottom>
                            Quick Links
                        </Typography>
                        <Link href="#" color="inherit" underline="hover">Home</Link><br />
                        <Link href="#" color="inherit" underline="hover">About Us</Link><br />
                        <Link href="#" color="inherit" underline="hover">Services</Link><br />
                        <Link href="#" color="inherit" underline="hover">Contact</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" component="p" gutterBottom>
                            Contact Info
                        </Typography>
                        <Typography>123 Security Blvd, Safe City</Typography>
                        <Typography>Phone: (123) 456-7890</Typography>
                        <Typography>Email: info@security.com</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" component="p" gutterBottom>
                            Follow Us
                        </Typography>
                        <IconButton color="inherit" href="https://facebook.com">
                            <FacebookIcon />
                        </IconButton>
                        <IconButton color="inherit" href="https://twitter.com">
                            <TwitterIcon />
                        </IconButton>
                        <IconButton color="inherit" href="https://linkedin.com">
                            <LinkedInIcon />
                        </IconButton>
                        <IconButton color="inherit" href="https://instagram.com">
                            <InstagramIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" component="p" gutterBottom>
                            Legal
                        </Typography>
                        <Link href="#" color="inherit" underline="hover">Privacy Policy</Link><br />
                        <Link href="#" color="inherit" underline="hover">Terms of Use</Link>
                    </Grid>
                </Grid>
                <Typography textAlign="center" sx={{ mt: 3 }}>
                    &copy; {new Date().getFullYear()} Security Solutions. All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
};
