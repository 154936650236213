import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import accessLogo from '../assets/img/logo.png';
import accessLogoWhite from '../assets/img/logo-white.png';
import { AuthService } from '../services/auth';
import { Box, useMediaQuery } from '@mui/material';
import UserAvatarCard from './Avatar';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.paper, // Set AppBar's backgroundColor to match Drawer's backgroundColor
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const TopAppBar = ({ open, handleDrawerOpen }: any) => {
    const theme = useTheme();

    const logo = theme.palette.mode === 'dark' ? accessLogoWhite : accessLogo;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const openSidebar = isSmallScreen ? false : open;

    return (
        <AppBar position="absolute" open={openSidebar}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    sx={{
                        marginRight: '36px',
                        ...(openSidebar && { display: 'none' }),
                    }}
                >
                    {
                        !isSmallScreen ?
                            <MenuIcon /> : null
                    }
                </IconButton>
                <img src={logo} alt="Access Logo" width="250" />
                <Box sx={{ flexGrow: 1 }} />
                <UserAvatarCard />
            </Toolbar>
        </AppBar>
    );
};
