import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';

import { useCurrentCompany } from '../../utils/hooks';

import * as API from '../../controllers/api';
import { useToast } from '../../components/Toast';
import { getCompanyById } from '../../utils/helpers';

const CompanyDialog = ({ open, handleClose, isEdit }) => {  
  const { 
    currentCompany, 
    setCurrentCompany,
  } = useCurrentCompany();  
  
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    contractCode: '',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSwitchChange = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: checked,
  //   });
  // };

  const handleValidateFormData = () => {

    if (!formData.companyName) {
      showToast('Event name is required', 'error', 3000);
      return false;
    }

    if (!formData.contractCode) {
      showToast('Event date is required', 'error', 3000);
      return false;
    }

    return true;
  }

  const handleSave = () => {    
    if (!handleValidateFormData()) return;

    setLoading(true);

    const action = isEdit
      ? API.updateCompanyName(currentCompany, formData.companyName, formData.contractCode)  
      : API.createCompany(formData.companyName, formData.contractCode);

    action
      .then(() => {
        showToast(`Successfully created company: ${formData.companyName}`, 'info', 3000);
        handleClose({ success: true });
      })
      .catch((error) => {
        console.error(error);
        showToast(`Failed to create company: ${formData.companyName}`, 'error', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (isEdit && currentCompany) {
      let company = getCompanyById(currentCompany);
      setFormData({
        companyName: company?.companyName ?? '',
        contractCode: company?.contractCode ?? '',
      });
    }
  }, [
    currentCompany,
  ]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{isEdit ? 'Update Company' : 'Create New Company'}</DialogTitle>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 5, }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent>
            <TextField
              margin="dense"
              name="companyName"
              label="Company Name"
              fullWidth
              value={formData.companyName}
              onChange={handleInputChange}
            />

            <TextField
              margin="dense"
              name="contractCode"
              label="Contract Code"
              fullWidth
              value={formData.contractCode}
              onChange={handleInputChange}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CompanyDialog;
