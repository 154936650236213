import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';

import { useCurrentCompany } from '../../utils/hooks';

import * as API from '../../controllers/api';
import { useToast } from '../../components/Toast';

const EventDialog = ({ open, handleClose, eventId }) => {
  const { currentCompany, setCurrentCompany } = useCurrentCompany();  
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    // isNfcEnabled: false,
    // isQrEnabled: false,
  });

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      API.getEventById(currentCompany, eventId)
        .then((response) => {
          setFormData({
            name: response.data.name,
            startDate: new Date(response.data.startDate).toISOString().split('T')[0],
            // isNfcEnabled: response.data.isNfcEnabled,
            // isQrEnabled: response.data.isQrEnabled,
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [eventId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleValidateFormData = () => {

    if (!formData.name) {
      showToast('Event name is required', 'error', 3000);
      return false;
    }

    if (!formData.startDate) {
      showToast('Event date is required', 'error', 3000);
      return false;
    }

    return true;
  }

  const handleSave = () => {    
    if (!handleValidateFormData()) return;

    setLoading(true);

    const action = eventId 
      ? API.updateEvent(eventId, {}) 
      : API.createEvent({ 
        companyId: currentCompany,
        isNfcEnabled: true,
        isQrEnabled: true,
        name: formData.name,
        startDate: new Date(formData.startDate).getTime(),
    });

    action
      .then(() => {
        showToast(`Successfully created event: ${formData.name}`, 'info', 3000);
        handleClose({ success: true });
      })
      .catch((error) => {
        console.error(error);
        showToast(`Failed to create event: ${formData.name}`, 'error', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{eventId ? 'Update Event' : 'Create New Event'}</DialogTitle>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 5, }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent>
            <TextField
              margin="dense"
              name="name"
              label="Event Name"
              fullWidth
              value={formData.name}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="startDate"
              label="Event Date"
              type="date"
              fullWidth
              value={formData.startDate}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* <FormControlLabel
              control={
                <Switch
                  checked={formData.isNfcEnabled}
                  onChange={handleSwitchChange}
                  name="isNfcEnabled"
                />
              }
              label="Enable NFC"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isQrEnabled}
                  onChange={handleSwitchChange}
                  name="isQrEnabled"
                />
              }
              label="Enable QR"
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              {eventId ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EventDialog;
