import * as React from 'react';
import { CircularProgress, TextField, Box, Grid, IconButton, Paper, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { isAdmin, isSuperAdmin } from '../../utils/helpers';
import { ReportScheduleItem, Site, User } from '../../utils/models';
import SiteDialog from '../dialogs/SiteDialog';
import EditIcon from '@mui/icons-material/Edit';
import UserDialog from '../dialogs/UserDialog';
import { getReports } from '../../controllers/api';
import { useCurrentCompany } from '../../utils/hooks';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportDialog from '../dialogs/ReportDialog';

interface ScheduledReportSectionProps {
    siteList: Site[];
}

export const ScheduledReportSection = (
    props: ScheduledReportSectionProps,
) => {
    const { currentCompany, setCurrentCompany } = useCurrentCompany();

    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [showReportDialiog, setShowReportDialog] = React.useState(false);
    const [editReport, setEditReport] = React.useState<ReportScheduleItem | null>(null);
    const [reports, setReports] = React.useState<ReportScheduleItem[]>([]);

    const fetchReports = async () => {

        try {
            setLoading(true);

            const response = await getReports(currentCompany);
            setReports(response.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setReports([]);
        }
    }

    let filteredReports = reports;


    React.useEffect(() => {
        fetchReports();
    }, []);

    if (!isAdmin()) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <Grid container justifyContent="space-between" alignItems="center" marginBottom={3}>
                <Title>Scheduled Reports ({reports.length})</Title>
                {isAdmin() && (
                    <Button color="primary" aria-label="add device" variant='outlined' onClick={() => setShowReportDialog(true)}>
                        Add +
                    </Button>
                )}
            </Grid>
            <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                {reports.length > 10 && (
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                )}
                {loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box style={{ maxHeight: '400px', overflow: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Send to</TableCell>
                                    <TableCell>Site Ids</TableCell>
                                    <TableCell>On Day</TableCell>
                                    <TableCell>Last Sent</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredReports.map((row) => (
                                    <TableRow key={row['reportId']}>
                                        <TableCell>
                                            <PictureAsPdfIcon />
                                        </TableCell>
                                        <TableCell>{
                                            Array.isArray(row['sendTo']) ? row['sendTo'].join(',') : JSON.stringify(row['sendTo']) 
                                        }</TableCell>
                                        <TableCell>{
                                            Array.isArray(row['siteIds']) ? row['siteIds'].join(',') : JSON.stringify(row['siteIds'])
                                        }</TableCell>
                                        <TableCell>{row['daysOfWeek']?.map(d => getDayOfWeekStr(d)).join(',')}</TableCell>
                                        <TableCell>
                                            {row['lastSent']}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    setEditReport(row);
                                                    setShowReportDialog(true);
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )}
            </Paper>

            <ReportDialog
                siteOptions={props.siteList}
                handleClose={() => {
                    setShowReportDialog(false);
                    setEditReport(null);
                }}
                onSuccess={() => {
                    fetchReports();
                }}
                open={showReportDialiog}
                currentReport={editReport}
            />
        </React.Fragment >
    );
}


const getDayOfWeekStr = (dayOfWeek: string) => {
    switch (dayOfWeek) {
        case '1':
            return 'Monday';
        case '2':
            return 'Tuesday';
        case '3':
            return 'Wednesday';
        case '4':
            return 'Thursday';
        case '5':
            return 'Friday';
        case '6':
            return 'Saturday';
        case '7':
            return 'Sunday';
        default:
            return 'Unknown';
    }
}