import { Delete } from "@mui/icons-material";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";


type BlacklistTableProps = {
    blacklist: any[],
    page: number,
    rowsPerPage: number,
    handleChangePage: (event: unknown, newPage: number) => void,
    handleBlacklistDelete: (id: string) => void,
    handleChangeRowsPerPage: any,
};

export const BlacklistTable = ({ blacklist, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleBlacklistDelete }: BlacklistTableProps) => {
    return <TableContainer
        component={Paper}
        sx={{ padding: 2 }}
    >
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>ID Nr/Vehicle Reg/Contractor ID</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {blacklist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow key={row.id}>
                        <TableCell>{row.value}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => {
                                    handleBlacklistDelete(row.id);
                                }}
                            >
                                <Delete />
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={blacklist.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} />
    </TableContainer>;
}
