import React from 'react';
import { Card, CardContent, CardHeader, Grid, Container, Typography } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import ReportIcon from '@mui/icons-material/Report';
import SmsIcon from '@mui/icons-material/Sms';
import GavelIcon from '@mui/icons-material/Gavel';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const featureList = [
  {
    title: "Access Reports",
    description: "Generate detailed reports for insights into access patterns and security breaches.",
    Icon: ReportIcon,
  },
  {
    title: "Scan IDs",
    description: "Support for scanning various IDs, contractor cards, drivers licenses, and car disks.",
    Icon: SecurityIcon,
  },
  {
    title: "Gate Control",
    description: "Automated gate control for seamless entry and exit.",
    Icon: LockOpenIcon,
  },
  {
    title: "SMS Notifications",
    description: "Instant SMS alerts for access events, security breaches, or system updates.",
    Icon: SmsIcon,
  },
  {
    title: "Blacklisting & Whitelisting",
    description: "Easily manage access permissions with customizable lists.",
    Icon: GavelIcon,
  },
  {
    title: "Mobile Integration",
    description: "Use your smartphone for secure and convenient access control.",
    Icon: PhoneIphoneIcon,
  },
];

export const FeaturesSection = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h4" component="h2" gutterBottom textAlign="center" sx={{ fontWeight: 'bold' }}>
        Key Features
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {featureList.map((feature) => (
          <Grid item xs={12} sm={6} md={4} key={feature.title}>
            <Card sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <CardHeader
                avatar={<feature.Icon sx={{ fontSize: 40 }} />}
                title={feature.title}
                titleTypographyProps={{ align: 'center', variant: 'h6' }}
              />
              <CardContent>
                <Typography variant="body1" color="textSecondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
