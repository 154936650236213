import * as React from 'react';
import Title from './Title';
import { CompanyDropdown } from '../../components/CompanyDropdown';
import { Box, Button, Paper } from '@mui/material';
import UserAvatarCard from '../../components/Avatar';
import { isSuperAdmin } from '../../utils/helpers';
import EditIcon from '@mui/icons-material/Edit';

export const CompanyInfo = ({onEditClicked }) => {
  return (
    <div>
      {/* */}
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CompanyDropdown />
        {isSuperAdmin() && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onEditClicked && onEditClicked()}        
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Box>
        )}

      </Paper>

      {/* <Paper
        elevation={3}
        sx={{
          marginTop: 3,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        Total Sites, Devices, Users
      </Paper> */}
    </div>
  );
};
