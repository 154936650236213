import { Button, Container, Typography, Box } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';

export const AppDownloadPage = () => {
  const handleDownload = () => {
    window.open('https://peak-public-ns.s3.af-south-1.amazonaws.com/access_ctrl_release.apk', '_blank');
  };

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box textAlign="center" mb={4}>
        <AndroidIcon style={{ fontSize: 100 }} />
      </Box>
      <Box textAlign="center" mb={2}>
        <Typography variant="h3">Download</Typography>
      </Box>
      <Box textAlign="center" mb={4}>
        <Typography variant="h6"> 
            Download the Access [CTRL] app to get started with your organization.            
        </Typography>
      </Box>
      <Button variant="contained" color="primary" onClick={handleDownload}>
        Download APK
      </Button>
        <Box mt={5} />     
    </Container>
  );
};
