import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AuthService } from '../services/auth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import { EventBus, EventType } from '../utils/events';

const UserAvatarCard = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const user = AuthService.getLoggedInUser()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        window.location.pathname = '/';
        EventBus.getInstance().emitEvent(EventType.LOGOUT);
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={handleClick}
                sx={{ ml: 2, textAlign: 'left' }}
            >
                <Box sx={{
                    mx: 2,
                    display: {
                        xs: 'none',  
                        md: 'block',
                    },
                }} color={'white'} >
                    <Box color={'grey'} fontSize={'.75rem'} textTransform={'none'}>{user?.email ?? ''} ({user?.username ?? ''})</Box>
                </Box>
                <AccountCircleIcon htmlColor='#FFF' />

            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleLogout}>
                    <LogoutIcon color='primary' sx={{ mr: 3 }} />
                    Logout
                    <ChevronRightIcon color='primary' sx={{ ml: 3 }} />
                </MenuItem>
                {/* <MenuItem onClick={handleLogout}>
                    <SettingsIcon color='primary' sx={{ mr: 3 }} />
                    Settings
                    <ChevronRightIcon color='primary' sx={{ ml: 3 }} />
                </MenuItem> */}
            </Menu>
        </Box>
    );
};

export default UserAvatarCard;
