import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
  Autocomplete,
} from '@mui/material';

import { useCurrentCompany } from '../../utils/hooks';
import * as API from '../../controllers/api';
import { useToast } from '../../components/Toast';
import { AccessType, Device, ReportScheduleItem } from '../../utils/models';
import { EventType } from '@testing-library/react';

const getNumberForDay = (day: string) => {
  switch (day) {
    case 'MONDAY':
      return '1';
    case 'TUESDAY':
      return '2';
    case 'WEDNESDAY':
      return '3';
    case 'THURSDAY':
      return '4';
    case 'FRIDAY':
      return '5';
    case 'SATURDAY':
      return '6';
    case 'SUNDAY':
      return '7';
    default:
      return '0';
  }
}



// Define your ReportDialog component
const ReportDialog = ({ open, handleClose, onSuccess, currentReport, siteOptions }) => {
  currentReport = currentReport as ReportScheduleItem;
  const isEdit = !!currentReport;

  const { currentCompany } = useCurrentCompany();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  // Initial form state
  const [formData, setFormData] = useState({
    sendTo: '',
    siteIds: [] as any[],
    daysOfWeek: [] as any[],
  });



  // Function to handle input changes for non-custom fields
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target as HTMLInputElement;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValidateFormData = () => {
    if (formData.sendTo.length === 0) {
      showToast('Send to is required', 'error', 3000);
      return false;
    }

    if (formData.siteIds.length === 0) {
      showToast('Site is required', 'error', 3000);
      return false;
    }

    if (formData.daysOfWeek.length === 0) {
      showToast('Day of week is required', 'error', 3000);
      return false;
    }

    return true;
  };

  const handleDelete = () => {
    if (!isEdit) return;
    setLoading(true);

    API.deleteScheduledReport(currentCompany, currentReport.reportId)
      .then(() => {
        showToast('Successfully deleted report', 'info', 3000);
        handleClose({ success: true });
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        showToast('Failed to delete report', 'error', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSave = () => {
    if (!handleValidateFormData()) return;

    setLoading(true);

    const report = {
      companyId: currentCompany,
      sendTo: formData.sendTo.split(',').map((email) => email.trim()),
      siteIds: formData.siteIds,
      daysOfWeek: formData.daysOfWeek.map((day) => getNumberForDay(day)),
    };

    const action = isEdit
      ? API.updateScheduledReport(currentCompany, currentReport.reportId, report)
      : API.createScheduledReport(currentCompany, report);

    action
      .then(() => {
        showToast(
          `Successfully ${isEdit ? 'updated' : 'saved'} report
          }`,
          'info',
          3000
        );
        handleClose({ success: true });
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        showToast(
          `Failed to ${isEdit ? 'update' : 'save'} report
          }`,
          'error',
          3000
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Populate default form data if editing
  useEffect(() => {
    if (isEdit) {
      setFormData({
        sendTo: currentReport.sendTo ?? '',
        siteIds: currentReport.siteIds ?? [],
        daysOfWeek: currentReport.daysOfWeek?.map((day) => {
          switch (day) {
            case '1':
              return 'MONDAY';
            case '2':
              return 'TUESDAY';
            case '3':
              return 'WEDNESDAY';
            case '4':
              return 'THURSDAY';
            case '5':
              return 'FRIDAY';
            case '6':
              return 'SATURDAY';
            case '7':
              return 'SUNDAY';
            default:
              return '';
          }
        }
        ) ?? [],
      });
    } else {
      setFormData({
        sendTo: '',
        siteIds: [],
        daysOfWeek: [],
      });
    }
  }, [currentReport]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>{isEdit ? 'Edit Scheduled Report' : 'Add Scheduled Report'}</DialogTitle>
      {loading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 5 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent>
            <TextField
              margin="dense"
              name="sendTo"
              label="Send to"
              fullWidth
              value={formData.sendTo}
              onChange={handleInputChange}
              helperText="Comma separated email addresses"
            />

            <Autocomplete
              multiple
              id="siteIds"
              options={siteOptions.map((option) => option.siteCode)}
              value={formData.siteIds}
              onChange={(e, value) => setFormData((prevState) => ({ ...prevState, siteIds: value }))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  name="siteIds"
                  label="Site"
                  fullWidth
                />
              )}
            />

            <Autocomplete
              multiple
              id="daysOfWeek"
              options={[
                'MONDAY',
                'TUESDAY',
                'WEDNESDAY',
                'THURSDAY',
                'FRIDAY',
                'SATURDAY',
                'SUNDAY',
              ]}
              value={formData.daysOfWeek}
              onChange={(e, value) => {
                setFormData((prevState) => ({
                  ...prevState,
                  daysOfWeek: value,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  name="daysOfWeek"
                  label="Day of week"
                  fullWidth
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            {
              isEdit &&
              < Button onClick={handleDelete} color="error">
                Delete
              </Button>
            }
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </>
      )
      }
    </Dialog >
  );
};

export default ReportDialog;
