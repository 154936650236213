import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Toolbar,
  Button,
  CircularProgress,
} from '@mui/material';
import Title from '../dashboard/Title';
import { EventsTable } from './EventTable';
import EventDialog from './EventDialog'; // Import the EventDialog component
import { deleteEventById, getEvents } from '../../controllers/api';
import { useCurrentCompany } from '../../utils/hooks';
import { useToast } from '../../components/Toast';

export const EventsPage = () => {
  const { currentCompany } = useCurrentCompany();
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = ({success}) => {
    setOpen(false);
    if (success) {
      window.location.reload();
    }
  };

  const handleDeleteEvent = async (event) => {
    if (!event || !event.id) return;

    try {
      await deleteEventById(currentCompany, event.id);
      window.location.href = '/events';
      showToast('Event deleted', 'success', 3000);
    } catch (error) {
      showToast('Cannot delete event', 'error', 3000);
    }
  }

  React.useEffect(() => {
    const fetchEvents = async () => {

      setLoading(true);

      const eventsResponse = await getEvents(currentCompany);
      setEvents(eventsResponse.data);

      setLoading(false);
    };

    fetchEvents();
  }, [currentCompany]);

  if (loading) {
    return (
      <Box component="main" sx={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container justifyContent="space-between" alignItems="center" marginBottom={3}>
          <Title>Events ({events.length})</Title>
          <Button color="primary" aria-label="add device" variant='contained' onClick={handleClickOpen}>
            Create Event +
          </Button>
        </Grid>
        <EventsTable
          handleDeleteEvent={handleDeleteEvent}
          events={events}
          handleChangePage={() => { }}
          handleChangeRowsPerPage={() => { }}
          page={0}
          rowsPerPage={100}
        />
      </Container>
      <EventDialog
        open={open}
        handleClose={handleClose}
        eventId={undefined} />
    </Box >
  );
};
