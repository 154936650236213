import { Box, PaletteMode, createTheme } from "@mui/material"
import { HeroSection } from "./components/hero_section"
import accessLogoWhite from '../../assets/img/logo.png';
import { ThemeProvider } from "@emotion/react";
import { AboutUs } from "./components/about_us";
import { FeaturesSection } from "./components/features";
import { WhyChooseUs } from "./components/why_us";
import { ContactForm } from "./components/contact_us";
import { Footer } from "./components/footer";

export const MarketingPage = () => {

    return <Box width={'100%'} sx={{}}>

        <Box
            flex={'1'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            bgcolor={'white'}
            boxShadow={
                '0px 4px 4px rgba(0, 0, 0, 0.25)'
            }>
            <img src={accessLogoWhite} alt="Access Logo" width="250" />
        </Box>
        <HeroSection />
        <FeaturesSection />
        <AboutUs />
        <WhyChooseUs />
        <ContactForm />
        <Footer/>
    </Box>
}