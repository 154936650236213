import axios from 'axios';
import { Device } from '../utils/models';


// export const BASE_API_URL = 'https://dev.api.access.peaksoftware.co.za';
export const BASE_API_URL = 'https://prod.api.access.peaksoftware.co.za';

const api = axios.create({
    baseURL: BASE_API_URL
});

// Add a request interceptor
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = 'Bearer ' + token;
    return config;
});

// Add a response interceptor
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // alert('An error occurred: ' + error.message);
        return Promise.reject(error);
    }
);

export const getCompanies = async (): Promise<any> => {
    return api.get('/companies');
};

export const getDevices = (companyId: string): Promise<any> => {
    if (!companyId) {
        return Promise.resolve({ data: [] });
    }

    return api.get(`/companies/${companyId}/devices`);
};

export const getSites = (companyId: string): Promise<any> => {
    if (!companyId) {
        return Promise.resolve({ data: [] });
    }

    return api.get(`/companies/${companyId}/sites`);
};

// {{API_URL}}/visits?fromDate=0&toDate=10000000000000&siteIds=FEST
export const getLatestVisits = (siteIds: string[]): Promise<any> => {
    if (!siteIds || siteIds.length === 0) {
        return Promise.resolve({ data: { visits: [] } });
    }

    // from the last 24 hours
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 2);

    // to now
    const toDate = new Date();

    // conver to unix timestamp

    return api.get(`/visits?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}&siteIds=${siteIds.join(',')}`);
    // return api.get(`/visits?fromDate=0&toDate=1000000000000000&siteIds=${siteIds.join(',')}`);
};

export const getVisits = (siteId: string, fromDate: Date, toDate: Date): Promise<any> => {
    return api.get(`/visits?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}&siteIds=${siteId}`);
};

export const getImage = (objectKey: string): Promise<any> => {
    return api.get(`/img?objectKey=${encodeURIComponent(objectKey)}`);
};


export const getEventAttendees = async (companyId, eventId: string): Promise<any> => {
    return api.get(`/events/${companyId}/${eventId}/attendees`);
}

export const updateEvent = async (eventId, eventBody): Promise<any> => {
    return;
}


export const createEvent = async ({
    companyId,
    name,
    startDate,
    isNfcEnabled,
    isQrEnabled,
}): Promise<any> => {
    await api.post(`/events/${companyId}`, {
        name,
        startDate,
        isNfcEnabled,
        isQrEnabled,
    });

    return;
}

export const getEventById = async (companyId: string, eventId: string): Promise<any> => {
    return api.get(`/events/${companyId}/${eventId}`);
}

export const deleteEventById = async (companyId: string, eventId: string): Promise<any> => {
    return api.delete(`/events/${companyId}/${eventId}`);
}


export const getEvents = async (companyId: string): Promise<any> => {
    return api.get(`/events/${companyId}`);
}

export const handlePresignedEventImport = async (companyId: string, eventId: string) => {
    return api.post(`/events/${companyId}/${eventId}/attendees`);
}

export const downloadLogsReport = async (companyId: string, siteId: string, fromDate: string, toDate: string, mailTo: string | undefined) => {
    let fromMinDate = new Date(fromDate);
    fromMinDate.setHours(0, 0, 0, 0);

    let toMaxDate = new Date(toDate);
    toMaxDate.setHours(23, 59, 59, 999);

    let startDate = fromMinDate.getTime();
    let endDate = toMaxDate.getTime();

    let mailToParam = mailTo ? `&mailTo=${mailTo}` : '';

    return api.post(`/reports/${companyId}/${siteId}/generate?startDate=${startDate}&endDate=${endDate}${mailToParam}`);
}

export const getBlacklistsForCompany = async (companyId: string) => {
    return api.get(`/blacklists/${companyId}`);
}

export const addBlacklistForCompany = async (companyId: string, value: string, description: string) => {
    return api.post(`/blacklists/${companyId}`, {
        value,
        description,
    });
}

export const deleteBlacklistForCompany = async (companyId: string, id: string) => {
    return api.delete(`/blacklists/${companyId}/${id}`);
}


export const createCompany = async (companyName: string, contractCode: string) => {
    return api.post('/companies', {
        companyName,
        contractCode,
    });
}

export const createSite = async (companyId: string, siteCode: string, siteName: string) => {
    return api.post(`/companies/${companyId}/sites`, {
        siteCode,
        siteName,
        coordsX: 28.0,
        coordsY: -26.0,
    });
}

export const updateSiteName = async (companyId: string, siteCode: string, siteName: string) => {
    return api.put(`/companies/${companyId}/sites/${siteCode}`, {
        siteName,
    });
}

export const createDevice = async (companyId: string, device: Device) => {
    return api.post(`/companies/${companyId}/devices`, device);
}

export const updateDevice = async (companyId: string, deviceId: string, device: Device) => {
    return api.put(`/companies/${companyId}/devices/${deviceId}`, device);
}

export const updateCompanyName = async (companyId: string, companyName: string, contractCode: string) => {
    return api.put(`/companies/${companyId}`, {
        companyName,
        contractCode,
    });
}


export const createNewUser = async (user: any) => {
    return api.post(`/users`, user);
}

export const getUsers = async () => {
    return api.get(`/users`);
}

export const updateUser = async (username: string, user: any) => {
    return api.put(`/users/${username}`, user);
}

export const sendPasswordEmail = async (username: string) => {
    return api.post(`/users/${username}/password-email`);
}

export const getReports = async (companyId: string) => {
    return api.get(`/reports/${companyId}/schedule`);
}

export const createScheduledReport = async (companyId: string, report: any) => {
    return api.post(`/reports/${companyId}/schedule`, report);
}

export const updateScheduledReport = async (companyId: string, reportId: string, report: any) => {
    return api.put(`/reports/${companyId}/schedule/${reportId}`, report);
}

export const deleteScheduledReport = async (companyId: string, reportId: string) => {
    return api.delete(`/reports/${companyId}/schedule/${reportId}`);
}
