import React, { useRef, useEffect } from 'react';
import { VectorMap } from '@react-jvectormap/core';
import zaMill from "@react-jvectormap/southafrica/dist/zaMerc.json";
import { Site } from '../utils/models';
import { Box } from '@mui/material';

interface SAMapCardProps {
    siteList: Site[];
}

export const SAMapCard: React.FC<SAMapCardProps> = ({ siteList }) => {
    const mapRef = useRef(null);
    const markers = siteList.map((site) => ({
        latLng: [site.coordsX, site.coordsY],
        name: site.siteName + ' (' + site.siteCode + ')',
        style: {
            fill: 'red',
        }
    }));

    return (
        <Box sx={{
            "& .jvectormap-zoomin, & .jvectormap-zoomout": {
                display: "none",
            },
            height: '100%',
            width: '100%',
        }}>
            <VectorMap
                mapRef={mapRef}
                map={zaMill}
                backgroundColor="#383f4700"
                markers={markers}
                markersSelectable
                zoomOnScroll={false}
                zoomAnimate
                focusOn={{
                    scale: 2,
                    lat: -28.4792625,
                    lng: 24.6727136,
                    animate: true,
                    x: 0,
                    y: 0,
                }}
                markersSelectableOne
                panOnDrag={false}
                regionLabelStyle={{
                    initial: {
                        cursor: 'default',
                        fill: 'grey',
                    },
                    hover: {
                        cursor: 'pointer',
                    },
                }}
            //   onRegionClick={onRegionClick}
            //   onMarkerSelected={onMarkerSelected}
            />
        </Box>


    );
};
