import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface BlacklistModalProps {
  open: boolean;
  onClose: () => void;
  onBlacklistSubmit: (value: string, description: string) => Promise<boolean>;
}

const AddBlacklistDialog: React.FC<BlacklistModalProps> = ({ open, onClose, onBlacklistSubmit }) => {
  const [value, setValue] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async () => {
    const success = await onBlacklistSubmit(value, description);
    if (success) {
      onClose(); // Close the modal on success
    } else {
      alert('Failed to blacklist. Please try again.'); // Handle failure (this could be improved)
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Blacklist Entry</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="value"
          label="Vehicle Registration Nr / ID Number"
          type="text"
          fullWidth
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBlacklistDialog;
