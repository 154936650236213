import * as React from 'react';
import { CircularProgress, TextField, Box, Grid, IconButton, Paper, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import {  isAdmin, isSuperAdmin } from '../../utils/helpers';
import { Site } from '../../utils/models';
import SiteDialog from '../dialogs/SiteDialog';
import EditIcon from '@mui/icons-material/Edit';

interface SiteListProps {
  siteList: Site[];
  onShouldRefresh: () => void;
}

export const Sites = (props: SiteListProps) => {
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [showSiteDialog, setShowSiteDialog] = React.useState(false);
  const [editSite, setEditSite] = React.useState<Site | null>(null);

  const filteredSites = props.siteList.filter((site) =>
    site.siteName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="center" marginBottom={3}>
        <Title>Sites ({props.siteList.length})</Title>
        {isAdmin() && (
          <Button color="primary" aria-label="add device" variant='outlined' onClick={() => setShowSiteDialog(true)}>
            Add +
          </Button>
        )}
      </Grid>
      <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

        {props.siteList.length > 10 && (
          <TextField
            label="Search"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box style={{ maxHeight: '400px', overflow: 'auto' }}  sx={{
            scrollbarColor: 'white transparent',
          }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Site Code </TableCell>
                  <TableCell>Site Name </TableCell>
                  <TableCell></TableCell>                
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSites.map((row) => (
                  <TableRow key={row['siteCode']}>
                    <TableCell>{row['siteCode']}</TableCell>
                    <TableCell>{row['siteName']}</TableCell>
                    <TableCell>                    
                      {isAdmin() && (
                        <IconButton
                          onClick={() => {
                            setEditSite(row);
                            setShowSiteDialog(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}                    
                    </TableCell>
                  </TableRow>
                ))}                
              </TableBody>
            </Table>
          </Box>
        )}
      </Paper>

      <SiteDialog
        handleClose={() => {
          setShowSiteDialog(false);
          setEditSite(null);          
        }}        
        onSuccess={props.onShouldRefresh}
        open={showSiteDialog}
        currentSite={editSite}
      />
    </React.Fragment>
  );
}
