import { useState } from 'react';
import { Dialog, DialogContent, CircularProgress, Button, Box } from '@mui/material';
import { getImage } from '../controllers/api';

interface S3DialogLinkProps {
    objectKey: any;
}

const S3DialogLink: React.FC<S3DialogLinkProps> = ({ objectKey }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imgSrc, setImgSrc] = useState("");

    const handleOpen = async () => {
        setOpen(true);

        const data = await getImage(objectKey);  
        setImgSrc(data.data.imageUrl);     

        setLoading(false);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(true);
        setImgSrc("");
    };

    return (
        <>
            <Button onClick={handleOpen} variant='text' size='small' sx={{ padding: 0 }}>
                View Image
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <img src={imgSrc} alt="" height={'auto'} width={450} />
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default S3DialogLink;
