import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import QrCodeIcon from '@mui/icons-material/QrCode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventDialog from './EventDialog';
import { deleteEventById } from '../../controllers/api';

type EventTableProps = {
    events: any[],
    page: number,
    rowsPerPage: number,
    handleChangePage: (event: unknown, newPage: number) => void,
    handleDeleteEvent: (event: any) => void,
    handleChangeRowsPerPage: any,
};


export const EventsTable = ({ events, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleDeleteEvent }: EventTableProps) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [editEventId, setEditEventId] = useState(null);

    const handleOpenDialog = (eventId) => {
        setEditEventId(eventId);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setEditEventId(null);
    };

    const handleView = (eventId) => {
        navigate(`/events/${eventId}`);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <TableContainer component={Paper} sx={{ padding: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Event ID</TableCell>
                        <TableCell style={{ width: '25%' }}>Event Name</TableCell>
                        <TableCell>Event Date</TableCell>
                        <TableCell>Attendees</TableCell>
                        <TableCell>Access Methods</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                            <TableRow key={row.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/events/${row.id}`)}>
                                <TableCell>{row.id.substr(0, 8)}</TableCell>
                                <TableCell sx={{ flex: 2 }}>{row.name}</TableCell>
                                <TableCell>{new Date(row.startDate).toLocaleDateString()}</TableCell>
                                <TableCell>{row.attendeeCount}</TableCell>
                                <TableCell>
                                    <Tooltip title="QR Enabled">
                                        {row.isQrEnabled && <QrCodeIcon />}
                                    </Tooltip>
                                    <Tooltip title="NFC Enabled">
                                        {row.isNfcEnabled && <TapAndPlayIcon />}
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Button
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClick(e);
                                        }}
                                    >
                                        <MoreVertIcon />
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {/* <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenDialog(row.id);  // open the edit dialog
                                                handleClose();
                                            }}
                                        >
                                            Edit
                                        </MenuItem> */}
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteEvent(row);
                                                handleClose();
                                            }}
                                        >
                                            Delete
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleView(row.id);  // Navigate to view
                                                handleClose();
                                            }}
                                        >
                                            View
                                        </MenuItem>
                                    </Menu>
                                </TableCell>

                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <EventDialog
                open={isDialogOpen}
                handleClose={handleCloseDialog}
                eventId={editEventId}
            />
        </TableContainer>
    );
};
