import { Button, Typography, Box, Grid, Container } from '@mui/material';

export const HeroSection = () => {
    return (
        <Container
            style={{
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            sx={{
                marginY: { xs: 2, md: 8 },
            }}>

            <Grid
                height={'100%'}
                container
                spacing={2}
                alignItems="center"
                justifyContent="center">

                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        textAlign: { xs: 'center', md: 'left' },
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        sx={{ fontWeight: 'bold' }}>

                        Revolutionize Your Security
                    </Typography>

                    <Typography
                        variant="h6"
                        component="p"
                        sx={{ mb: 4 }}>
                        Seamless, Secure, and Smart Access Control Systems for Your Business
                    </Typography>

                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ my: 1, mx: 1 }}>

                            Get a Free Demo
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{ my: 1, mx: 1 }}>

                            Contact Us for a Quote
                        </Button>
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={4}
                    textAlign="center">

                    <Box
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>

                        <img src="https://via.placeholder.com/300x300" />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};