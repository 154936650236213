import { AuthService } from "../services/auth";

export const isAdmin = () => {
  return AuthService.isAdmin() || AuthService.isSuperAdmin();
};

export const isSuperAdmin = () => {
  return AuthService.isSuperAdmin();
};

export const saveAllCompaniesLocal = (companies: any[]) => {
  localStorage.setItem('allCompanies', JSON.stringify(companies));
}

export const getAllCompaniesLocal = () => {
  return JSON.parse(localStorage.getItem('allCompanies') || '[]');
}

export const getCompanyById = (companyId: string) => {
  const companies = getAllCompaniesLocal();
  return companies.find((c) => c.PK.split('#')[1] === companyId);
}