import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';

import { useCurrentCompany } from '../../utils/hooks';

import * as API from '../../controllers/api';
import { useToast } from '../../components/Toast';
import { getCompanyById } from '../../utils/helpers';
import { Site } from '../../utils/models';

const SiteDialog = ({ open, handleClose, currentSite, onSuccess }) => {
  currentSite = currentSite as Site;
  const isEdit = !!currentSite;

  const {
    currentCompany,
    setCurrentCompany,
  } = useCurrentCompany();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    siteCode: '',
    siteName: '',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleValidateFormData = () => {

    if (!formData.siteName) {
      showToast('Site Name is required', 'error', 3000);
      return false;
    }

    if (!formData.siteCode) {
      showToast('Site Code is required', 'error', 3000);
      return false;
    }

    return true;
  }

  const handleSave = () => {
    if (!handleValidateFormData()) return;

    setLoading(true);
    const siteCode = formData.siteCode;
    const siteName = formData.siteName
    const action = isEdit
      ? API.updateSiteName(currentCompany, (currentSite as Site).siteCode, siteName)
      : API.createSite(currentCompany, siteCode, siteName);

    action
      .then(() => {
        showToast(`Successfully saved site: ${formData.siteName}`, 'info', 3000);
        handleClose({ success: true });
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        showToast(`Failed to site: ${formData.siteName}`, 'error', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (isEdit) {
      setFormData({
        siteCode: currentSite.siteCode,
        siteName: currentSite.siteName,
      });
    } else {
      setFormData({
        siteCode: '',
        siteName: '',
      });
    }
  }, [
    currentSite,
  ]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{isEdit ? 'Update Site' : 'Create New Site'}</DialogTitle>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 5, }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent>

            <TextField
              margin="dense"
              name="siteCode"
              label="Site Code"
              fullWidth
              disabled={isEdit}
              value={formData.siteCode}
              onChange={handleInputChange}
              helperText="Site code is unique and cannot be changed, used to link a site to a device"
            />
            <TextField
              margin="dense"
              name="siteName"
              label="Site Name"
              fullWidth
              value={formData.siteName}
              onChange={handleInputChange}
            />


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SiteDialog;
