import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export type ToastType = 'success' | 'info' | 'warning' | 'error';

export interface ToastProps {
    id: string;
    message: string;
    type: ToastType;
    duration: number;
}


interface ToastContextProps {
    showToast: (message: string, type: ToastType, duration: number) => void;
    removeToast: (id: string) => void;
}

interface ToastProviderProps {
    children: ReactNode;
}

const ToastContext = createContext<ToastContextProps | null>(null);

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }: any) => {
    const [toasts, setToasts] = useState<ToastProps[]>([]);

    const showToast = (message: string, type: ToastType, duration: number) => {
        const id = uuidv4();
        setToasts((toasts) => [...toasts, { id, message, type, duration }]);
    };

    const removeToast = (id: string) => {
        setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{ showToast, removeToast }}>
            {children}
            {toasts.map((toast) => (
                <Toast key={toast.id} id={toast.id} message={toast.message} type={toast.type} duration={toast.duration} />
            ))}
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastContextProps => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

export const Toast: React.FC<ToastProps> = ({ id, message, type, duration }) => {
    const { removeToast } = useToast();
    const [open, setOpen] = React.useState(true);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
        }, duration);
        return () => {
            clearTimeout(timer);
        };
    }, [duration]);

    const handleClose = (event?: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => {
        if (!open) {
            removeToast(id);
        }
    }, [open, id, removeToast]);

    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={(e, r) => handleClose(e, r)}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

