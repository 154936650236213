import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';

const usps = [
  {
    title: 'Advanced Technology',
    description: 'Leverage cutting-edge technology for robust security and seamless access control.',
  },
  {
    title: 'Ease of Use',
    description: 'Intuitive interfaces and straightforward administration for all our solutions.',
  },
  {
    title: 'Scalability',
    description: 'Solutions that grow with your business, ensuring long-term relevance and value.',
  },
  {
    title: 'Customer Support',
    description: 'Dedicated support from our team of experts, available 24/7 to assist you.',
  },
];

export const WhyChooseUs = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h4" component="h2" gutterBottom textAlign="center" sx={{ fontWeight: 'bold' }}>
        Why Choose Us
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {usps.map((usp, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={3} sx={{ p: 2, minHeight: 180, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h3" sx={{ mb: 1, fontWeight: 'medium' }}>
                {usp.title}
              </Typography>
              <Typography variant="body1">
                {usp.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
