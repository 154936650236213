import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Box,
  Typography,
  Autocomplete,
} from '@mui/material';

import * as API from '../../controllers/api';
import { useToast } from '../../components/Toast';
import { Site, User } from '../../utils/models';
import { PermissionType } from '../../services/auth';
import { Label } from '@mui/icons-material';

const UserDialog = ({ open, handleClose, currentUser, onSuccess, siteOptions }) => {
  currentUser = currentUser as User;
  const isEdit = !!currentUser;

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    companyIds: [] as any[],
    email: '',
    firstName: '',
    isAdmin: false,
    lastName: '',
    permissions: [] as any[],
    siteIds: [] as any[],
  });


  const handleInputChange = (e, isList = false) => {
    const { name, value } = e.target;

    if (isList) {
      setFormData({
        ...formData,
        [name]: value.split(','),
      });
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleValidateFormData = () => {

    if (!formData.username) {
      showToast('Username is required', 'error', 3000);
      return false;
    }

    if (!formData.email) {
      showToast('Email is required', 'error', 3000);
      return false;
    }

    if (!formData.firstName) {
      showToast('First Name is required', 'error', 3000);
      return false;
    }

    if (!formData.lastName) {
      showToast('Last Name is required', 'error', 3000);
      return false;
    }

    return true;
  }

  const handleSendPasswordEmail = () => {
    setLoading(true);

    API.sendPasswordEmail(currentUser.username)
      .then(() => {
        showToast(`Successfully sent password email to: ${currentUser.username}`, 'info', 3000);
      })
      .catch((error) => {
        console.error(error);
        showToast(`Failed to send password email to: ${currentUser.username}`, 'error', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSave = () => {
    if (!handleValidateFormData()) return;

    setLoading(true);

    const action = isEdit
      ? API.updateUser(currentUser.username, {
        companyIds: formData.companyIds,
        email: formData.email,
        firstName: formData.firstName,
        isAdmin: formData.isAdmin,
        lastName: formData.lastName,
        permissions: formData.permissions ?? [],
        siteIds: formData.siteIds ?? [],
      })
      : API.createNewUser({
        username: formData.username,
        companyIds: formData.companyIds,
        email: formData.email,
        firstName: formData.firstName,
        isAdmin: formData.isAdmin,
        lastName: formData.lastName,
        permissions: formData.permissions,
        siteIds: formData.siteIds,
      });

    action
      .then(() => {
        showToast(`Successfully saved user: ${formData.username}`, 'info', 3000);
        handleClose({ success: true });
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        showToast(`Failed to user: ${formData.username}`, 'error', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (isEdit) {
      setFormData({
        username: currentUser.username,
        companyIds: currentUser.companyIds,
        email: currentUser.email,
        firstName: currentUser.firstName,
        isAdmin: currentUser.isAdmin,
        lastName: currentUser.lastName,
        permissions: currentUser.permissions,
        siteIds: currentUser.siteIds,
      });
    } else {
      setFormData({
        username: '',
        companyIds: [],
        email: '',
        firstName: '',
        isAdmin: false,
        lastName: '',
        permissions: [],
        siteIds: [],
      });
    }
  }, [
    currentUser,
  ]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{isEdit ? 'Update User' : 'Create New User'}</DialogTitle>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 5, }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogContent>

            <TextField
              margin="dense"
              name="username"
              label="Username"
              fullWidth
              disabled={isEdit}
              value={formData.username}
              onChange={handleInputChange}
              helperText="Unique username for the user"
            />

            <TextField
              margin="dense"
              name="email"
              label="Email"
              fullWidth
              value={formData.email}
              onChange={handleInputChange} />

            <TextField
              margin="dense"
              name="firstName"
              label="First Name"
              fullWidth
              value={formData.firstName}
              onChange={handleInputChange} />

            <TextField
              margin="dense"
              name="lastName"
              label="Last Name"
              fullWidth
              value={formData.lastName}
              onChange={handleInputChange} />

            Admin users can add sites
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isAdmin}
                  onChange={(e) => setFormData({ ...formData, isAdmin: e.target.checked })}
                />
              }
              label="isAdmin"
            />
            <br />
            <br />

            Permissions
            <br />
            {
              Object.values(PermissionType).map((permission) => {

                try {

                  return <FormControlLabel
                    key={permission}
                    control={
                      <Switch
                        checked={formData.permissions.includes(permission)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({ ...formData, permissions: [...formData.permissions, permission] });
                          } else {
                            setFormData({ ...formData, permissions: formData.permissions.filter((p) => p !== permission) });
                          }
                        }}
                      />
                    }
                    label={permission}
                  />
                } catch (error) {
                  return <></>;
                }
              })
            }

            <br />
            <br />
            All the companies the user has access to, if none then the user has access to all companies
            <TextField
              margin="dense"
              name="companyIds"
              label="Company Ids"
              fullWidth
              value={formData.companyIds.join(',')}
              onChange={(value) => {
                handleInputChange(value, true);
              }}
              helperText="Company Ids comma seperated"
            />


            <br />
            <br />



            All the sites the user has access to, if none then the user has access to all sites for all companies
            <Autocomplete
              multiple
              id="siteIds"
              options={siteOptions.map((option) => option.siteCode)}
              value={formData.siteIds}
              onChange={(e, value) => setFormData((prevState) => ({
                ...prevState,
                siteIds: value,              
              }))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  name="siteIds"
                  label="Site"
                  fullWidth
                />
              )}
            />




          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>

            {
              isEdit &&
              <Button onClick={handleSendPasswordEmail} color="primary">
                Email New Password
              </Button>
            }
            <Button onClick={handleSave} color="primary">
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default UserDialog;
