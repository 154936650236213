import axios from "axios";
import jwt from 'jwt-decode';
import { BASE_API_URL } from "../controllers/api";

export type UserInfo = {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    companyIds: string[];

    permissions?: string[];
    isAdmin?: boolean;
    isSuperAdmin?: boolean;

    // jwt
    iat: number;
    exp: number;
}


export enum PermissionType {
    ADMIN_GATE_CONTROL = 'ADMIN_GATE_CONTROL',
    ADMIN_EVENTS = 'ADMIN_EVENTS',
}



export class AuthService {
    static async login(username: string, password: string) {
        try {
            const resp = await axios.post(BASE_API_URL + '/login', {}, {
                auth: {
                    username: username,
                    password: password
                }
            });

            // set token
            const token = resp?.data?.token ?? '';
            localStorage.setItem('token', token);

            // set current company 
            const user = this.getLoggedInUser();
            const companyIds = user?.companyIds ?? [];
            if (companyIds.length > 0) {
                localStorage.setItem('currentCompany', companyIds[0]);
            }

            return true;
        } catch (error) {
            console.log('Error while logging in...', error);
            throw error;
        }
    }

    static isAuthenticated() {
        // check if token is present in local storage
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        // check if token is valid
        try {
            const decodedToken = jwt(token) as any;

            // check if token is expired       
            if (decodedToken.exp * 1000 < Date.now()) {
                console.log('Token is expired...');
                return false;
            }

            return true;
        } catch (error) {
            console.log('Error while decoding token...', error);
            return false;
        }
    }

    static async logout() {
        localStorage.removeItem('token');
    }

    static isAdmin = () => {
        const user = this.getLoggedInUser();
        return user?.isAdmin ?? false;
    }

    static isSuperAdmin = () => {
        const user = this.getLoggedInUser();
        return user?.isSuperAdmin ?? false;
    }

    static getLoggedInUser(): UserInfo | null {
        const token = localStorage.getItem('token');
        if (!token) {
            return null;
        }

        const user = jwt(token);
        return user as UserInfo;
    }

    static hasPermission(p: PermissionType) : boolean {
        let user = this.getLoggedInUser();

        if (!user) {
            return false;
        }

        if (user.isSuperAdmin) {
            return true;
        }

        return user?.permissions?.includes(p) ?? false;
    }
}