import React, { useState, useRef } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
    Typography,
    Link,
    Box,
    FormControl,
    FormHelperText,
} from '@mui/material';
import * as API from '../../controllers/api';
import { useToast } from '../../components/Toast';
import { useCurrentCompany } from '../../utils/hooks';
import Papa from 'papaparse';

const CSV_HEADERS = ['type', 'typeId'];

const EventAttendeesImportDialog = ({ open, handleClose, eventId, handleSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [presignedData, setPresignedData] = useState<any>(null);
    const [errors, setErrors] = useState<any[]>([]);
    const { showToast } = useToast();
    const { currentCompany } = useCurrentCompany();
    const fileInputRef = useRef<any>();

    const downloadTemplate = () => {
        const blob = new Blob([CSV_HEADERS.join(',') + '\n'], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const validateFile = async (file) : Promise<any[]> => {
        setErrors([]);
        return new Promise((resolve) => {
            Papa.parse(file, {      
                skipEmptyLines: true,                                                          
                complete: (result) => {
                    const errors = [] as any[];
                    result.data.forEach((row, index) => {
                        if (index == 0) return;

                        if (row.length === 1){
                            if (row[0].trim() === '') return;
                            errors.push(`Row ${index + 1}: Missing field`);
                        }
                    
                        if (row.length !== CSV_HEADERS.length) {
                            errors.push(`Row ${index + 1}: Incorrect number of fields`);
                        } else if (!['QR', 'NFC'].includes(row[0])) {
                            errors.push(`Row ${index + 1}: Invalid type ${row[0]}`);
                        }
                    });
                    resolve(errors);
                },
            });
        });
    };

    const handleImport = async () => {
        fileInputRef.current.click();
    };

    const handleFileSelected = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setLoading(true);
            try {
                const fileErrors = await validateFile(selectedFile);
                if (fileErrors.length > 0) {
                    setErrors(fileErrors);
                    return;
                }
                setFile(selectedFile);
                const response = await API.handlePresignedEventImport(currentCompany, eventId);
                setPresignedData(response.data);
                console.log('presignedData', presignedData);

                const { url, fields } = response.data;
                const formData = new FormData();
                Object.entries(fields).forEach(([key, value]) => {
                    formData.append(key, value as any);
                });
                formData.append('file', selectedFile);
                await fetch(url, { method: 'POST', body: formData });

                showToast('Attendees imported successfully', 'success', 3000);
                handleSuccess();
                handleClose();
            } catch (error) {
                console.error('Error uploading file', error);
                showToast('Failed to import attendees', 'error', 3000);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                Import Attendees <br />
                <Typography color={'GrayText'} fontSize={'0.90rem'}>
                    Import attendees from a CSV file. All current attendees will be replaced
                </Typography>
                <Link fontSize={'0.8rem'} style={{ cursor: 'pointer' }} onClick={downloadTemplate}>
                    Download CSV Template
                </Link>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <FormControl variant="outlined" fullWidth error={errors.length > 0}>
                        <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileSelected} accept=".csv" />
                        {errors.length > 0 && (
                            <FormHelperText>
                                {errors.map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))}
                            </FormHelperText>
                        )}
                    </FormControl>
                    {loading && <CircularProgress />}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={handleImport} color="primary" disabled={loading}>
                    Import
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EventAttendeesImportDialog;
