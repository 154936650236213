import * as React from 'react';
import { CircularProgress, Box, IconButton, Grid, Paper, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { getDevices } from '../../controllers/api';
import { useCurrentCompany } from '../../utils/hooks';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { isAdmin, isSuperAdmin } from '../../utils/helpers';
import DeviceDialog from '../dialogs/DeviceDialog';
import EditIcon from '@mui/icons-material/Edit';
import { Device } from '../../utils/models';


export const Devices = () => {
  const { currentCompany, setCurrentCompany } = useCurrentCompany();
  const [deviceData, setDeviceData] = React.useState<Device[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [showDeviceDialog, setShowDeviceDialog] = React.useState(false);
  const [currentDevice, setCurrentDevice] = React.useState<Device | null>(null);

  const handleGetDevices = async () => {
    try {
      setLoading(true);
      const response = await getDevices(currentCompany);
      setDeviceData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDeviceData([]);
    }
  };

  React.useEffect(() => {
    handleGetDevices();
  }, [currentCompany]);

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="center" marginBottom={3}>
        <Title>Devices ({deviceData.length})</Title>
        {isSuperAdmin() && (
          <Button color="primary" aria-label="add device" variant='outlined' onClick={() => setShowDeviceDialog(true)}>
            Add +
          </Button>
        )}
      </Grid>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box style={{ maxHeight: '400px', overflow: 'auto' }}
            sx={{
              scrollbarColor: 'white transparent',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Device ID</TableCell>
                  <TableCell>Latest Activity</TableCell>
                  {isAdmin() && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceData.map((row) => (
                  <TableRow key={row.deviceId}>
                    <TableCell>{row.deviceName ?? '-'}</TableCell>
                    <TableCell>{row.deviceId}</TableCell>
                    <TableCell>
                      {row.lastAccessedDate && !isNaN(row.lastAccessedDate as any) && (
                        new Date(Number(row.lastAccessedDate)).toLocaleString('en-US', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                          weekday: 'short',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                        })
                      )}
                    </TableCell>
                    <TableCell>
                      {
                        isAdmin() && (
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setCurrentDevice(row);
                              setShowDeviceDialog(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Paper>

      <DeviceDialog
        currentDevice={currentDevice}
        handleClose={() => {
          setShowDeviceDialog(false);
          setCurrentDevice(null);
        }}
        open={showDeviceDialog}
        onSuccess={handleGetDevices}
      />
    </React.Fragment>
  );
};
