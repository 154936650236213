import { Typography, Box, Grid, Container } from '@mui/material';

export const AboutUs = () => {
    return (
        <Container
            style={{
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4rem 0', // Add some padding top and bottom
            }}>

            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center">

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        textAlign: { xs: 'center', md: 'left' },
                        mb: { xs: 2, md: 0 }, // Margin bottom on xs screens
                    }}>

                    <Typography
                        variant="h4"
                        component="h2"
                        gutterBottom
                        sx={{ fontWeight: 'bold' }}>
                        About Us
                    </Typography>

                    <Typography
                        variant="h6"
                        component="p"
                        sx={{ mb: 2 }}>
                        Pioneering the Future of Access Control
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{ mb: 2 }}>
                        Our mission is to deliver cutting-edge security solutions that are not only seamless and secure but also smart and scalable. With over a decade of innovation, our team is dedicated to revolutionizing how businesses manage access control, ensuring safety without compromising on convenience.
                    </Typography>

                    <Typography
                        variant="body1">
                        From advanced gate systems to integrated software solutions, we provide a comprehensive suite of services designed to meet the unique needs of each client. Trust us to protect your premises with our state-of-the-art technology and expert support.
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={6}
                    textAlign="center">
                    {/* Placeholder for any visual content or additional text */}
                    <Box
                        sx={{
                            // Example of how to add a background image or any visual content
                            // backgroundImage: 'url("your-image-url.jpg")',
                            // backgroundSize: 'cover',
                            // height: 300,
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                        }}>
                        Visual Content Here
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};
