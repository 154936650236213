import { createContext, useContext } from "react";

export const CompanyContext = createContext<{
  currentCompany: string;
  setCurrentCompany: React.Dispatch<React.SetStateAction<string>>;
}>({
  currentCompany: '',
  setCurrentCompany: () => { },
});

export const useCurrentCompany = () => useContext(CompanyContext);
