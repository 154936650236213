import { EventEmitter } from 'events';

export enum EventType {
    LOGOUT = 'LOGOUT',
    TOAST = 'TOAST',
}

export class EventBus {
  private static instance: EventBus;
  private eventEmitter: EventEmitter;

  private constructor() {
    this.eventEmitter = new EventEmitter();
  }

  public static getInstance(): EventBus {
    if (!EventBus.instance) {
      EventBus.instance = new EventBus();
    }
    return EventBus.instance;
  }

  public emitEvent(eventType: EventType, payload?: any): void {
    this.eventEmitter.emit(eventType, payload);
  }

  public onEvent(eventType: EventType, listener: (payload?: any) => void): void {
    this.eventEmitter.on(eventType, listener);
  }

  public removeListener(eventType: EventType, listener: (payload?: any) => void): void {
    this.eventEmitter.removeListener(eventType, listener);
  }
}
