import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { buildPersonInfo, buildVehicleInfo, VisitInfoDialog } from "./VisitDialog";

type LogPageProps = { logs: any[] };

export const LogsTable = ({ logs, }: LogPageProps) => {
    return <TableContainer
        component={Paper}
        sx={{ padding: 2 }}
    >
        <Table
            size="small"
        // sx={{ bgcolor: (theme) => theme.palette.background.default }}
        >
            <TableHead>
                <TableRow>
                    <TableCell>Event Type</TableCell>
                    <TableCell>Event Date</TableCell>
                    <TableCell>Access Type</TableCell>
                    <TableCell>Person Details</TableCell>
                    <TableCell>Vehicle Details</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {logs.map((row) => (
                    <TableRow key={row.logId}>
                         <TableCell>
                            <Box
                                display="flex"
                                justifyContent="start"
                                alignItems="flex-start"
                            >
                                {row.eventType === 'EXIT' ? <ExitToAppIcon /> : <ExitToAppIcon />}
                                {row.eventType}
                            </Box>
                        </TableCell>
                        <TableCell>{new Date(row.eventDate).toLocaleString()}</TableCell>
                        <TableCell>
                            <Box
                                display="flex"
                                justifyContent="start"
                                alignItems="flex-start"
                            >
                                {row.accessType === 'PEDESTRIAN' ? <DirectionsWalkIcon /> : row.accessType === 'CONTRACTOR' ?  <EngineeringIcon/>  : <DriveEtaIcon /> }
                                {row.accessType}
                            </Box>
                        </TableCell>
                        <TableCell>
                            {buildPersonInfo(row)}
                        </TableCell>
                        <TableCell>
                            {buildVehicleInfo(row)}
                        </TableCell>                        
                        <TableCell>{row.deviceId}</TableCell>
                        <TableCell>
                            <Box
                                display="flex"
                                justifyContent="start"
                                alignItems="flex-start"
                            >
                                <VisitInfoDialog visit={row} />
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>;
}
