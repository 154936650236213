import * as React from 'react';
import { CircularProgress, TextField, Box, Grid, IconButton, Paper, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { isAdmin, isSuperAdmin } from '../../utils/helpers';
import { Site, User } from '../../utils/models';
import SiteDialog from '../dialogs/SiteDialog';
import EditIcon from '@mui/icons-material/Edit';
import UserDialog from '../dialogs/UserDialog';
import { getUsers } from '../../controllers/api';


export const UserManagementSection = ({siteOptions}) => {
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [showSiteDialog, setShowUserDialog] = React.useState(false);
    const [editUser, setEditUser] = React.useState<User | null>(null);
    const [users, setUsers] = React.useState<User[]>([]);

    const fetchUsers = async () => {

        try {
            setLoading(true);

            const response = await getUsers();
            setUsers(response.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setUsers([]);
        }
    }

    const filteredUsers = users.filter((user) =>
        user.username.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.firstName.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(search.toLowerCase())
    );


    React.useEffect(() => {
        fetchUsers();
    }, []);

    if (!isSuperAdmin()) {
        return <React.Fragment></React.Fragment>;
    }



    return (
        <React.Fragment>
            <Grid container justifyContent="space-between" alignItems="center" marginBottom={3}>
                <Title>Users ({users.length})</Title>
                {isSuperAdmin() && (
                    <Button color="primary" aria-label="add device" variant='outlined' onClick={() => setShowUserDialog(true)}>
                        Add +
                    </Button>
                )}
            </Grid>
            <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                {users.length > 10 && (
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                )}
                {loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box style={{ maxHeight: '400px', overflow: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Username </TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>isAdmin</TableCell>
                                    <TableCell>Permissions</TableCell>
                                    <TableCell>Company IDs</TableCell>
                                    <TableCell>SiteIds</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.map((row) => (
                                    <TableRow key={row['username']}>
                                        <TableCell>{row['username']}</TableCell>
                                        <TableCell>{row['email']}</TableCell>
                                        <TableCell>{row['firstName']}</TableCell>
                                        <TableCell>{row['lastName']}</TableCell>
                                        <TableCell>{row['isAdmin'] ?
                                            'Yes' : 'No'
                                        }</TableCell>
                                        <TableCell>{
                                            row['permissions'].join(', ')
                                        }</TableCell>
                                        <TableCell>{
                                            row['companyIds'].join(', ')
                                        }</TableCell>
                                        <TableCell>{
                                            row['siteIds'].join(', ')
                                        }</TableCell>
                                        <TableCell>
                                            {isSuperAdmin() && (
                                                <IconButton
                                                    onClick={() => {
                                                        setEditUser(row);
                                                        setShowUserDialog(true);
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )}
            </Paper>

            <UserDialog
                siteOptions={siteOptions}
                handleClose={() => {
                    setShowUserDialog(false);
                    setEditUser(null);
                }}
                onSuccess={() => { }}
                open={showSiteDialog}
                currentUser={editUser}
            />
        </React.Fragment >
    );
}
