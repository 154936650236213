import { Box, Card, Container, Grid, Toolbar, Button, Tabs, Tab, CircularProgress, Typography } from '@mui/material';
import { useCurrentCompany } from '../../utils/hooks';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Title from '../dashboard/Title';
import * as API from '../../controllers/api';
import { EventsAttendeesTable } from './EventAttendeesTable';
import { DeleteRounded, ImportExport } from '@mui/icons-material';
import EventDialog from './EventDialog';
import EventAttendeesImportDialog from './EventAttendeesImportDialog';
import { useToast } from '../../components/Toast';

export const EventViewPage = () => {
  const { currentCompany, setCurrentCompany } = useCurrentCompany();
  const { id: eventId } = useParams(); // Get eventId from URL
  const [eventData, setEventData] = useState<any>(null);
  const [attendees, setAttendees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [eventEditOpen, setEventEditOpen] = useState(false);
  const [eventImportOpen, setEventImportOpen] = useState(false);
  const { showToast } = useToast();


  const refresh = async () => {
    if (!eventId) return;

    setLoading(true); // Reset loading to true each time refresh is called

    try {
      const [eventResponse, attendeesResponse] = await Promise.all([
        API.getEventById(currentCompany, eventId),
        API.getEventAttendees(currentCompany, eventId),
      ]);

      setEventData(eventResponse.data);
      setAttendees(attendeesResponse.data);
    } catch (error) {
      showToast('Cannot refresh event', 'error', 3000);
    }

    setLoading(false);
  };

  const handleDeleteEvent = async () => {
    if (!eventId) return;

    try {
      await API.deleteEventById(currentCompany, eventId);
      window.location.href = '/events';
      showToast('Event deleted', 'success', 3000);
    } catch (error) {
      showToast('Cannot delete event', 'error', 3000);
    }
  }


  useEffect(() => {
    refresh();
  }, [eventId]);

  if (loading) {
    return (
      <Box component="main" sx={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
        {/* Center the loader */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Box>
          {/* Back button */}
          <Button variant="outlined" href='/events' style={{
            marginRight: '16px',
            marginBottom: '16px',
          }}>
            Back
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card elevation={3} sx={{ marginBottom: 3, p: 3 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
                <Typography color={'GrayText'} >Name</Typography>
                <Typography>{eventData.name}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
                <Typography color={'GrayText'}>Attendees</Typography>
                <Typography>{eventData.attendeeCount}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
                <Typography color={'GrayText'}>Start</Typography>
                <Typography>{new Date(eventData.startDate).toLocaleDateString()}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
                <Typography color={'GrayText'}>NFC Enabled</Typography>
                <Typography>{eventData.isNfcEnabled ? 'YES' : 'NO'}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
                <Typography color={'GrayText'}>QR Enabled</Typography>
                <Typography>{eventData.isQrEnabled ? 'YES' : 'NO'}</Typography>
              </Box>
            </Card>
            <Card elevation={3} sx={{ mt: 3 }}>
              <Box p={3} alignContent={'center'}>
                {/* <Button sx={{ mb: 2 }} variant="contained" fullWidth onClick={() => setEventEditOpen(true)}>
                  <EditCalendarRounded sx={{ marginRight: 2 }} />
                  Edit Event
                </Button> */}
                <Button variant="contained" fullWidth onClick={() => setEventImportOpen(true)}>
                  <ImportExport sx={{ marginRight: 2 }} />
                  Import Attendees
                </Button>
                {/* Delete */}
                <Button variant="outlined" color='error' fullWidth sx={{ mt: 2 }} onClick={() => handleDeleteEvent()}>
                  <DeleteRounded sx={{ marginRight: 2 }} />
                  Delete Event
                </Button>

                {/* <Box display="flex" justifyContent="center" alignItems="flex-center" marginTop={3} color={'grey'}>
                  <LockPersonIcon sx={{ mr: 2 }} />
                  <span>Disabled</span>
                </Box> */}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Tabs style={{ marginBottom: '8px' }} value={tabIndex} onChange={(e, val) => setTabIndex(val)}>
              <Tab label={`Attendees (${attendees.length})`} />
              {/* <Tab label="Event Logs" /> */}
            </Tabs>
            {tabIndex === 0 && <div>
              <EventsAttendeesTable
                rowsPerPage={100}
                eventAttendees={attendees}
                handleChangePage={() => { }}
                handleChangeRowsPerPage={() => { }}
                page={0}
              />
            </div>}
            {/* {tabIndex === 1 && <div>
              <EventsLogsTable
                rowsPerPage={100}
                eventLogs={logs}
                handleChangePage={() => { }}
                handleChangeRowsPerPage={() => { }}
                page={0}
              /></div>} */}
          </Grid>
        </Grid>
      </Container>
      <EventDialog
        open={eventEditOpen}
        handleClose={({ success }) => {
          setEventEditOpen(false);
          if (success) refresh();
        }}
        eventId={eventId}
      />
      <EventAttendeesImportDialog
        eventId={eventId}
        handleClose={() => {
          setEventImportOpen(false);
        }}
        handleSuccess={() => {
          refresh();
        }}
        open={eventImportOpen}
      />
    </Box>
  );
};
