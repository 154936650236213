import * as React from 'react';
import { useState, useEffect, createContext, useContext } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getCompanies } from '../controllers/api';
import { useCurrentCompany } from '../utils/hooks';
import { useToast } from './Toast';
import { saveAllCompaniesLocal } from '../utils/helpers';



export const CompanyDropdown = () => {
    const { showToast } = useToast();
    const [companies, setCompanies] = useState<any[]>([]);
    const { currentCompany, setCurrentCompany } = useCurrentCompany();    

    const handleSetCompany = (company: string) => {        
        localStorage.setItem('currentCompany', company);

        let companyName = companies.find((c) => c.PK.split('#')[1] === company)?.companyName;
        showToast(`Company set to ${companyName}`, 'info', 3000);
        setCurrentCompany(company);
    }


    useEffect(() => {
        getCompanies().then((res) => {
            setCompanies(res.data);
            saveAllCompaniesLocal(res.data);

            if (res.data.length > 0 && !currentCompany) {
                handleSetCompany(res.data[0].PK.split('#')[1]);
            }
        });
    }, []);

    const handleChange = (event: any) => {
        const selectedCompany = event.target.value as string;
        handleSetCompany(selectedCompany);
    };

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel id="company-select-label">Company</InputLabel>
                <Select
                    labelId="company-select-label"
                    id="company-select"
                    value={currentCompany}
                    label="Company"
                    onChange={handleChange}
                >
                    {companies.map((company) => (
                        <MenuItem key={company.PK.split('#')[1]} value={company.PK.split('#')[1]}>
                            {company.companyName} ({company.PK.split('#')[1]})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </React.Fragment>
    );
};
