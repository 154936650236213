import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SignInPage } from './pages/SignInPage';
import { CssBaseline, PaletteMode, createTheme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Dashboard from './pages/dashboard/Dashboard';
import { CompanyContext } from './utils/hooks';
import { TopAppBar } from './components/AppBar';
import { Sidebar } from './components/Sidebar';
import { Box } from '@mui/material';
import { LogsPage } from './pages/logs/LogsPage';
import { AuthService, PermissionType } from './services/auth';
import { EventBus, EventType } from './utils/events';
import { useToast } from './components/Toast';
import { EventsPage } from './pages/events/EventsPage';
import { EventViewPage } from './pages/events/EventViewPage';
import { AppDownloadPage } from './pages/app-download/AppDownloadPage';
import { NotFoundPage } from './pages/404';
import { MarketingPage } from './pages/marketing';
import { BlacklistPage } from './pages/blacklist/BlacklistPage';
import { AdminPage } from './pages/admin-page/AdminPage';

const generateTheme = (mode: PaletteMode) => {
  const primaryColor = '#0088de';
  const secondaryColor = '#6595b5'; // replace with your secondary color

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      background: {
        default: mode === 'light' ? '#fefefe' : '#1c1c1c',
        paper: mode === 'light' ? '#fefefe' : '#0a0e15',
      },
      text: {
        primary: mode === 'dark' ? '#dedede' : '#1e1e1e',
        secondary: mode === 'dark' ? '#fff' : '#1e1e1e',
      },
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: { // Apply this style to all TableCell components
            "&.MuiTableCell-head": {
              color: '#808080 !important',
            },
            "&.MuiTableCell-root": {
              color: '#e0e0e0',
            },
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 3,
        },
        styleOverrides: {
          rounded: {
            borderRadius: 10, // adjust this value as needed
          },
        },
      },
    },
  });

  return theme;
};


export default function App() {
  document.title = 'Access CTRL | Web';

  const [isAuthenticated, setIsAuthenticated] = React.useState(AuthService.isAuthenticated());
  const [currentCompany, setCurrentCompany] = React.useState(localStorage.getItem('currentCompany') || '');
  const { showToast } = useToast();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    AuthService.logout();
    showToast('Logged out successfully', 'success', 3000);
    setIsAuthenticated(false);
  }

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    showToast('Logged in successfully', 'success', 3000);

    if (window.location.pathname === '/login') {
      window.location.href = '/dashboard';
    }
  }

  const theme = React.useMemo(
    () => createTheme(generateTheme(!prefersDarkMode ? 'dark' : 'dark')),
    [prefersDarkMode],
  );

  React.useEffect(() => {
    EventBus.getInstance().onEvent(EventType.LOGOUT, handleLogout);

    return () => {
      EventBus.getInstance().removeListener(EventType.LOGOUT, handleLogout);
    };
  }, []);

  React.useEffect(() => {

    if (!AuthService.isAuthenticated() && isAuthenticated) {
      handleLogout();
    }
  }, [
    handleLogout,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        display: 'flex', height: '100vh',
        overflow: 'auto',
        scrollbarColor: '#0088de transparent',
      }}>
        <CompanyContext.Provider value={{ currentCompany, setCurrentCompany }}>
          {
            !isAuthenticated
              ? <Router>
                <Routes>
                  <Route path="/marketing" element={<MarketingPage />} />
                  <Route path="/app" element={<AppDownloadPage />} />
                  <Route path="/login" element={<SignInPage onLoginSuccess={handleLoginSuccess} />} />
                  <Route path="/" element={<SignInPage onLoginSuccess={handleLoginSuccess} />} />
                  <Route path="*" element={<SignInPage onLoginSuccess={handleLoginSuccess} />} />
                </Routes>
              </Router>
              : <Router>
                <TopAppBar open={open} handleDrawerOpen={toggleDrawer} />
                <Sidebar open={open} handleDrawerClose={toggleDrawer} />
                <Routes>
                  {/* GATE CONTROL */}
                  {AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) && <Route path="/dashboard" element={<Dashboard />} />}
                  {AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) && <Route path="/" element={<Dashboard />} />}
                  {AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) && <Route path="/logs" element={<LogsPage />} />}
                  {AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) && <Route path="/blacklist" element={<BlacklistPage />} />}

                  {/* EVENTS */}
                  {AuthService.hasPermission(PermissionType.ADMIN_EVENTS) && <Route path="/events" element={<EventsPage />} />}
                  {AuthService.hasPermission(PermissionType.ADMIN_EVENTS) && <Route path="/events/:id" element={<EventViewPage />} />}

                  {/* HOME PAGE IF THEY DON HAVE GATE */}
                  {
                    !AuthService.hasPermission(PermissionType.ADMIN_GATE_CONTROL) &&
                    AuthService.hasPermission(PermissionType.ADMIN_EVENTS)
                    && <Route path="/" element={<EventsPage />} />}

                  {/* 404 */}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Router>
          }
        </CompanyContext.Provider >
      </Box>
    </ThemeProvider>
  );
}
